import React from "react"

import Layout from "../components/layout"

import styles from "./mentions-legales.module.scss"

const MentionsLegales = () => {
  return (
    <Layout>
      <main className={styles.mainContainer}>
        <article>
          <h1>Mentions légales</h1>
          <p>
            Le présent site, son nom de domaine, l’ensemble des appellations,
            des éléments de forme, des programmes d’ordinateur, des bases de
            données du site, sont protégés pour le monde entier notamment par
            des droits de propriété intellectuelle. A défaut d’autorisation
            préalable et écrite de la part de la société Wistibike toute
            utilisation en tout ou partie de ces éléments est interdite et
            passible de poursuites judiciaires, notamment pour contrefaçon.
            <br />
            <br />
            En application de la loi n°2004-801 du 6 août 2004 relative à la
            protection des données à caractère personnel et modifiant la loi
            n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
            aux libertés, l’utilisateur est informé que les données à caractère
            personnel le concernant communiquées par lui lors de la création de
            son compte client et/ou d’une commande sont enregistrées dans le
            fichier clients de Wistibike. Ces données font l’objet d’un
            traitement automatisé destiné à la gestion de son compte client, de
            sa commande et à l’envoi d’informations liées, ainsi qu’au respect
            des dispositions légales ou réglementaires relatives notamment à
            l’archivage du contrat, à l’exercice du droit de rétractation ou des
            garanties légales.
            <br />
            <br />
            Les données à caractère personnel collectées lors de la création du
            compte client et/ou de la commande pourront également être utilisées
            par Wistibike, par ses services internes et ceux de l’ensemble des
            sociétés du groupe auquel elle appartient, par l’ensemble de ses
            salariés habilités et de ceux du groupe auquel elle appartient, à
            des fins de traitement et de suivi des commandes, d’information des
            promotions, opérations spéciales et évènements à venir sur le Site
            par le biais de newsletters, de sondages, d’enquêtes de
            satisfaction, d’études marketing dans le but d’offrir des offres
            adaptées, et d’offres et de prospections commerciales.
            <br />
            <br />
            Ces données à caractère personnel pourront également être
            communiquées aux partenaires techniques ou commerciaux actuels ou à
            venir de Wistibike - éventuellement installés hors de l'Union
            européenne dans des pays offrant un niveau de protection équivalent
            à celui en vigueur en France - qui pourront, le cas échéant, les
            utiliser également à fins de traitement, de suivi ou de livraison
            des commandes, ou de l’exécution de toutes tâches sous-traitées par
            Wistibike. Ces données pourront également être communiquées aux
            mêmes partenaires afin d’information, de promotion et de prospection
            commerciale. Le client devra toutefois en ce cas l’accepter au
            moment de la création de son compte en cochant la case vide lui
            offrant cette possibilité.
          </p>
        </article>
      </main>
    </Layout>
  )
}

export default MentionsLegales
